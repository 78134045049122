import React, { useState,useEffect } from 'react'

import "./home.css"
import "./home1.css"
import {AiFillLinkedin} from "react-icons/ai"
import{BsFillArrowUpSquareFill} from "react-icons/bs"
import Testimonial1 from '../Testimonial/Testimonial1'
import Modal from '../Registration/RegisterModel'
import Registration from '../Registration/Registration'
import 'animate.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import ChatBox from '../ChatBox/Chatbox'
// images
import satish from "../asset/satish_Director-removebg-preview.png"
import channabasappa from "../asset/Channabasappa-removebg-preview.png"
import sujith from "../asset/sujith_director11.png"
import service from "../asset/Our-Services-img-1.png"
import Factories from "../asset/Factories.jfif"
import information from "../asset/Information.png"
import E_Commerce from "../asset/E-Commerce.png"
import NonBanking from "../asset/Non Banking.png"
import Retail  from "../asset/Retail.jfif"
import SolarEnergy from "../asset/Solar Energy.jfif"
import Aerospace from "../asset/Aerospace.jfif"
import Pharma from "../asset/Pharma.jfif"
import Telecom from "../asset/Telecom.png"
import Education from "../asset/Education.jfif"
import Technology from "../asset/Technology.png"
import BPO from "../asset/BPO.png"
import POSH from "../asset/POSHcompliance.jfif"
import pfesi from "../asset/PF & ESI.png"
import LLC from "../asset/LLC audits.jfif"
import HRing from "../asset/HR consultation.png"
import payslip from "../asset/Payslips.png"
import  training from "../asset/Training.png"
import labaourlaw from "../asset/Labour Law.jfif"
import Implementation from "../asset/Implementation.jfif"
import h222 from "../asset/home222.gif"


function Home() {
  const [showButton, setShowButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

//   const regdemo=()=>{
//     console.log(window.scrollY)
//     if(window.scrollY == 0){
//         window.scrollBy({
//             top: 500, // Scroll down by 500px
//             left: 0,
//             behavior: 'smooth' // Smooth scrolling
//         });
//     }else{
//         // alert(window.scrollY)
//     }


//   }
const movedown = () => {setTimeout(() => {
    console.log(window.pageYOffset);
if(window.pageYOffset < 200){
    window.scrollBy({
        top: 570,
        left: 0,
        behavior: 'smooth'
    });
}
}, 5000);
}
  useEffect(() => {
    movedown()

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
//   window.addEventListener('scroll', regdemo);
  const [counterOn,setCounterOn] = useState(false)
  return (
    <div className="container-fluid">
        <div className='mainHome'>
            {showButton && (
            <button onClick={scrollToTop} className="back-to-top">
                <BsFillArrowUpSquareFill/>
            </button>
                )}
            <div className="row">
                <div className="home121color">
                    <div className='home121' >
                        <div className="home121color">
                            <Modal  show={modalVisible} onClose={() => setModalVisible(false)}>
                                <div className="demomodal">
                                    <h1>Register For Demo</h1>
                                </div>
                                <Registration/>
                            </Modal>
                            <div className="cente container-fluid">
                                <h1>
                                    <h2 className='animate__animated animate__backInDown  my-element '>
                                        Never miss our SERVICES
                                    </h2>
                                    <p className='home1p animate__animated animate__backInDown  '>
                                        Best Outsourcing Service Provider in India
                                    </p>
                                    <button className='border-0 bg-transparent animate__animated animate__backInRight '>
                                        <a onClick={() => setModalVisible(true)}  className='home1btn '>Register for Demo</a>
                                    </button>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="home2">
                    <div className='home2head'>
                        <h1 className='text-uppercase'>
                        Accunity HR Services Private Limited
                        </h1>
                    </div>
                    {/* <AnimationOnScroll animateIn="animate__heartBeat"> */}
                    <div className='home2data mt-0  '>
                            <div className="anim">
                                <p className='pt-5'>Accunity HR is a rapid growing SPO (Service Process Outsourcing) company dedicated to provide Payroll,
                                Labour Law Compliances, Statutory Compliance, Staffing Solutions & HR Consultation services. We are
                                committed to providing business solutions to our clients and address their concerns associated with
                                these services.
                                </p>
                                <p>Accunity HR provides a full assortment of payroll solution, which helps organizations streamline their
                                payroll processes and enable in better payroll administration. Our payroll solution can be personalized in
                                accordance to the customer requirement. We are experts in handling Employee Benefit Administration,
                                Tax Planning, Flexible Benefits/Compensation, Business reimbursement administration, Income tax
                                filing, etc.</p>
                                <p>
                                Accunity HR assist the companies to adhere to all the legislations in terms of Labour Acts. We have a
                                strong team of labour law compliance specialists. We shall serve our clients and make them worry free
                                in terms of labour legislations. We have reach in across India viz. Andhra Pradesh, Bihar, Chhattisgarh,
                                Delhi, Haryana, Goa, Gujarat, Jharkhand, Karnataka, Kerala, Madhya Pradesh, Odisha, Punjab, Rajasthan,
                                Tamil Nadu, Telangana, Uttarkhand, Uttar Pradesh, West Bengal.
                                </p>
                            </div>


                    <div className="anim anim111">
                        <img src={h222} className='animImgs' alt="" />
                    </div>
                    </div>
                    {/* </AnimationOnScroll> */}
                </div>
            </div>
                <div className="row home3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center mt-3 home3header">
                                <h1 className='text-uppercase'>OUR SERVICES</h1>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4 mt-md-5 ">
                                    <AnimationOnScroll animateIn="animate__backInLeft animate__duration-4s" className='custom-duration--1'>
                                    <div className="col-md-12 d-flex justifycontentend">
                                        <div className="col-md-10 service-link justifycontentend">
                                        <div className="col-md-12">
                                            <h3 className=' d-flex justifycontentend'>
                                        <a className='home-5-liservlnk p-1' title='know more about Labour Law Compliance' href="/service/labour">Labour Law Compliance</a></h3>
                                        </div>
                                        <div className="col-md-12">
                                        <h3 className=' col-md-12 d-flex justifycontentend'> <a className='home-5-liservlnk p-1' title='know more about Staffing Solutions' href="/service/statutory">Statutory Compliance</a></h3>
                                        </div>
                                        <div className="col-md-12 ">
                                            <h3 className='d-flex justifycontentend'><a className='home-5-liservlnk p-1' title='know more about HR Consulting' href="/service/consulting">HR Consulting</a></h3>
                                        </div>
                                        </div>
                                    </div>
                                    </AnimationOnScroll>

                                    </div>

                                    <div className="col-md-4 ">
                                    <AnimationOnScroll animateIn="animate__fadeInDown animate__duration-4s" className='custom-duration--1'>
                                    <picture className="col-md-12 d-flex justify-content-center">
                                        <img src={service} className='service-img' alt="" />
                                        </picture>
                                    </AnimationOnScroll>

                                    </div>
                                    <div className="col-md-4 service-link mt-md-5">
                                    <AnimationOnScroll animateIn="animate__fadeInRight animate__duration-4s" className='custom-duration--1 service-link'>
                                    <div className="col-md-10">
                                        <h3 className=' d-flex justifycontentstart'>

                                        <a className='home-5-liservlnk p-1' title='know more about Payroll Outsourcing Services' href="/service/payroll">Payroll Services</a>
                                        </h3>
                                    </div>
                                        <div className="col-md-10">
                                            <h3 className=' d-flex justifycontentstart'>
                                            <a className='home-5-liservlnk p-1' title='know more about Staffing Solutions' href="/service/staffing">Staffing Solutions</a>
                                            </h3>
                                        </div>
                                        <div className="col-md-10">
                                            <h3 className=' d-flex justifycontentstart'>
                                            <a className='home-5-liservlnk p-1' title='know more about Training & Development' href="/service/training">Training & Development</a>
                                            </h3>
                                        </div>
                                        </AnimationOnScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="home4 ">
                    <div className="container-lg">
                    <div className="home4head">
                    <h1 className='text-uppercase'>Industries we are serving</h1>
                    </div>
                    <div className="home4cardhead ">
                        <AnimationOnScroll animateIn="animate__backInLeft animate__duration-4s" className='custom-duration--1'>
                            <div className="home4cardr1 home4cardr1instus">

                                <div className='home4card home4card-r1 '>
                                    <img src={Factories} alt="" />
                                    <h1>Factories</h1>
                                </div>
                                <div className='home4card home4card-r1'>
                                <img src={information} alt="" />
                                    <h1 className='pb-1'>Information Technology </h1>
                                </div>


                                <div className='home4card home4card-r2'>
                                <img src={E_Commerce} alt="" />
                                    <h1>E-Commerce</h1>
                                </div>
                                <div className='home4card home4card-r2'>
                                <img src={NonBanking} alt="" />
                                    {/* <h1>Non Banking Financial Companies</h1> */}
                                    <h1>NBFC</h1>
                                </div>


                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__backInRight " className='custom-duration--1'>
                            <div  className='home4cardr1 home4cardr1instus'>
                            <div className='home4card home4card-r1'>
                            <img src={Retail} alt="" />
                                <h1>Retail </h1>
                            </div>
                            <div className='home4card home4card-r1'>
                            <img src={SolarEnergy} alt="" />
                                <h1>Solar Energy</h1>
                            </div>
                            <div className='home4card home4card-r2'>
                            <img src={Aerospace} alt="" />
                                <h1>Aerospace</h1>
                            </div>
                            <div className='home4card home4card-r2'>
                            <img src={Pharma} alt="" />
                                <h1>Pharma</h1>
                            </div>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__backInLeft" className='custom-duration--1'>
                            <div  className=' home4cardr1 home4cardr1instus '>
                            <div className='home4card home4card-r1'>
                            <img src={Telecom} alt="" />
                                <h1>Telecom</h1>
                            </div>
                            <div className='home4card home4card-r1'>
                            <img src={Education} alt="" />
                                <h1>Education</h1>
                            </div>
                            <div className='home4card home4card-r2'>
                            <img src={Technology} alt="" />
                                <h1>Technology</h1>
                            </div>
                            <div className='home4card home4card-r2'>
                            <img src={BPO} alt="" />
                                <h1>BPO</h1>
                            </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>

                </div>
                </div>
                <div className="row">
                <div className="home5">
                <div className="container-lg">
                    <div className="home5head "><h1 className='text-uppercase'>
                    We expertise in
                    </h1></div>
                    <div className='home5card'>
                    <AnimationOnScroll animateIn="animate__backInDown" className='custom-duration--1'>
                    <div className="home4cardr1">
                        <div className='home4card home4card-r1'>
                            <img src={labaourlaw} alt="" />
                            {/* <h1>Labour Law</h1> */}
                            <h1 className='pt-3'>Handling Labour Law
                                Compliance over 1200
                                units across country</h1>
                        </div>
                        <div className='home4card home4card-r1'>
                        <img src={LLC} alt="" />
                            {/* <h1>LLCaudits</h1> */}
                            <h1 className='pt-3'>Handling 50+ LLC
                                audits every month
                                </h1>
                        </div>
                        <div className='home4card home4card-r2 '>
                        <img src={payslip} alt="" />
                            {/* <h1>Payslips</h1> */}
                            {/* <p>Generating Payslips over 3000 per month</p> */}
                            <h1 className='pt-3'>Processing Payroll for 50+ companies (Generating Payslips more than 5000 per month)</h1>
                        </div>
                        <div className='home4card home4card-r2'>
                        <img src={pfesi} alt="" />
                            {/* <h1>PF & ESI</h1> */}
                            <h1 className='pt-3'>Handling PF & ESI for
                            over 5000 employees</h1>
                        </div>

                    </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__backInUp" className='custom-duration--1'>
                    <div className="home4cardr1">
                        <div className='home4card home4card-r1'>
                        <img src={POSH} alt="" />
                            {/* <h1>POSH compliance</h1> */}
                            <h1 className='pt-3'>Handling POSH compliance across PAN India</h1>
                        </div>

                        <div className='home4card home4card-r1'>
                        <img src={HRing} alt="" />
                            {/* <h1>HR
                            consultation</h1> */}
                            <h1 className='pt-3'>Providing HR
                                consultation across
                                country</h1>
                        </div>
                        <div className='home4card home4card-r2 ' id='home4card8'>
                        <img src={Implementation} alt="" />
                            {/* <h1>Implementation & <br />management</h1> */}
                            <h1 className='pt-3'>Implementation and management of NPS,Gratuity & Superannuation trust</h1>
                        </div>

                        <div className='home4card home4card-r2 home4cardprob2'>
                        <img src={training } alt="" />
                            {/* <h1>training sessions</h1> */}
                            <h1 className='pt-3'>Handled over 150
                                training sessions (over
                                500 hours of training)</h1>
                        </div>
                    </div>
                    </AnimationOnScroll>
                    </div>


                </div>
                </div>
                </div>
                <div className="row">
                <div className="home7">
                    <div className='home7head'>
                    <h1 className='text-uppercase'>
                        TEAM
                    </h1>
                    </div>
                    <div  className='homecard7head'>
                        <AnimationOnScroll animateIn="animate__backInLeft" className='custom-duration--1'>
                            <div className='home7card'>
                            <img src={sujith} alt="" />
                                <h1>Sujith Prakash S</h1>
                                <h5>Founder & Director</h5>
                                <a href="https://www.linkedin.com/in/sujith-prakash-s-b760131a/"><AiFillLinkedin /></a>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__backInUp" className='custom-duration--1'>
                            <div className='home7card'>
                                <img src={satish} alt="" />
                                <h1>Satish Kumar B</h1>
                                <h5>Founder & Director</h5>
                                <a href="https://www.linkedin.com/in/satish-kumar-b541b41a4/"><AiFillLinkedin /></a>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__backInRight" className='custom-duration--1'>
                            <div className='home7card'>
                                <img src={channabasappa} alt="" />
                                <h1>Channabasappa Yarashi</h1>
                                <h5>Founder & Director</h5>
                                <a href="https://www.linkedin.com/in/channabasappa-yarashi-25735a82/"><AiFillLinkedin /></a>
                            </div>
                        </AnimationOnScroll>

                    </div>
                </div>
                </div>
                <div className="row">
                <div className="home888">
                <Testimonial1/>
                </div>
                </div>
        </div>
    </div>
  )
}

export default Home
